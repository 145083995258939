import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import ImageCarousel from "../components/imageCarousel"
import InstagramFeed from "../components/instagramFeed"

const IndexPage = ({ data }) => {
  return (
  <Layout homepage>
    <Seo
      title="Home"
      keywords={[
        "women",
        "design",
        "collection",
        "slow fashion",
        "fashion",
        "Irene Bielsa",
      ]}
    />
    <ImageCarousel />
    <InstagramFeed rawPosts={data?.prismicInstagramPosts?.data?.posts} />
  </Layout>
)}

export default IndexPage

export const query = graphql`
  query instagramPostsPrismicQuery {
    prismicInstagramPosts {
      data {
        posts {
          post_link {
            url
          }
        }
      }
    }
  }
`