import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import Glide from "@glidejs/glide"
import Img from "gatsby-image"
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md"
import PropTypes from "prop-types"

import ShopButton from "./shop/shopButton"
import * as ImageCarouselStyles from "./imageCarousel.module.css"

// TODO load async within useEffect and test

const ImageCarousel = ({ items }) => {
  const [isMobile, setIsMobile] = useState(null)
  const [glide, setGlide] = useState(null)

  const loadGlide = React.useCallback(async () => {
    await import(
      "../../node_modules/@glidejs/glide/dist/css/glide.core.min.css"
    )
    await import(
      "../../node_modules/@glidejs/glide/dist/css/glide.theme.min.css"
    )

    // ? Glide options docs: https://glidejs.com/docs/options/#animationduration
    const _glide = new Glide(".glide", {
      type: "carousel",
      startAt: 0,
      perView: 1,
      gap: 0,
      animationDuration: 300,
    }).mount()

    setGlide(_glide)
  }, [])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isMobile !== window.matchMedia("(max-width: 767px)").matches) {
      setIsMobile(window.matchMedia("(max-width: 767px)").matches)
      loadGlide()
    }
    return function cleanup() {
      if (!!glide) {
        glide.destroy()
      }
    }
    /* eslint-disable no-sparse-arrays */
  }, [, loadGlide])

  const bullets = []

  const slides = items
    .sort((a, b) => a.order - b.order)
    .map((item, i) => {
      bullets.push(
        <button
          key={`bullet-${i}`}
          className={["glide__bullet", ImageCarouselStyles.bullet].join(" ")}
          data-glide-dir={`=${i}`}
          title={`Go to slide number ${i}`}
          aria-label={`Go to slide number ${i}`}
        ></button>
      )

      return (
        <li
          key={`slide-${item.order}`}
          className={["glide__slide", ImageCarouselStyles.slide].join(" ")}
        >
          <div>
            <Img
              fluid={item[isMobile ? "mobile_image" : "desktop_image"].fluid}
              fadeIn={true}
              style={{ minHeight: "90vh" }}
            />
            <ShopButton
              className={ImageCarouselStyles.shopButton}
              to={item.link_to_page.url?.split('irenebielsa.com')?.[1]}
              text="SHOP NOW"
            />
          </div>
        </li>
      )
    })

  return (
    <div className={["glide", ImageCarouselStyles.container].join(" ")}>
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">{slides}</ul>
      </div>
      <div className="glide__arrows" data-glide-el="controls">
        <MdKeyboardArrowLeft
          className={[ImageCarouselStyles.arrow, ImageCarouselStyles.left].join(
            " "
          )}
          aria-label={"Left arrow slide button"}
          data-glide-dir="<"
        />
        <MdKeyboardArrowRight
          className={[
            ImageCarouselStyles.arrow,
            ImageCarouselStyles.right,
          ].join(" ")}
          aria-label={"Right arrow slide button"}
          data-glide-dir=">"
        />
      </div>
      <div
        className={["glide__bullets", ImageCarouselStyles.bullets].join(" ")}
        data-glide-el="controls[nav]"
      >
        {bullets}
      </div>
    </div>
  )
}

ImageCarousel.propTypes = {
  items: PropTypes.array,
}

const DefaultImageCarousel = () => (
  <StaticQuery
    query={graphql`
      query homeCarouselImages {
        allPrismicHomepageBodyTopCarouselImages(
          sort: { fields: items___order }
        ) {
          nodes {
            items {
              desktop_image {
                fluid(maxWidth: 3920, maxHeight: 2080) {
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
              link_to_page {
                url
              }
              order
              mobile_image {
                fluid(maxWidth: 768, maxHeight: 1200) {
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
            slice_label
            slice_type
          }
        }
      }
    `}
    render={data => (
      <ImageCarousel
        items={data.allPrismicHomepageBodyTopCarouselImages.nodes[0].items}
      />
    )}
  />
)

export default DefaultImageCarousel