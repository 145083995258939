import React from 'react'
import InstagramPost from './instagramPost'
import * as InstagramFeedStyles from './instagramFeed.module.css'

const InstagramFeed = ({ rawPosts }) => {
  if (!rawPosts?.length) return null;

  const posts = rawPosts.map(post => {
    const postId = post.post_link.url.split('/')?.[4]
    if (!postId) return null
    return <InstagramPost key={postId} postId={postId} />
  }).filter(post => post);

  return (
    <div className={InstagramFeedStyles.instagramFeed}>{posts}</div>
  )
}

export default InstagramFeed