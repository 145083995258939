import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";

import * as ShopButtonStyles from "./shopButton.module.css"

const ShopButton = ({ to, text, onClickHandler, className = "" }) => {
  return (
    <AniLink
      fade
      to={to}
      className={[ShopButtonStyles.shopButton, className].join(" ")}
      onClick={onClickHandler ? onClickHandler : ''}
    >
      {text ? text.toUpperCase() : "SHOP"}
    </AniLink>
  )
}

export default ShopButton
